import { useHasProperty } from "@/hooks";
import useRole from "@/hooks/useRole";
import { Button, DataGrid } from "@/includes";
import { RootState } from "@/services/redux/store";
import { ROLE_ENUM } from "@/utils/enums/roles";
import { Box, Collapse, Stack, useTheme } from "@mui/material";
import { GridRowParams } from "@mui/x-data-grid-pro";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DeleteExperienceDialog from "./components/DeleteExperienceDialog";
import NoRowsOverlay from "./components/NoRowsOverlay";
import { REQUIRED_INTEGRATIONS } from "./enums";
import { ExperienceListProps } from "./types";
import useExperienceList from "./useExperienceList";
import Toolbar from "./components/Toolbar";
import DuplicateInOtherSiteDialog from "./components/DuplicateOnOtherSiteDialog";

const ExperienceList = ({ searchType }: ExperienceListProps) => {
  const { t }: i18translateType = useTranslation();
  const theme = useTheme();
  const requiredIntegrations = REQUIRED_INTEGRATIONS[searchType];
  const [hasRowClick, setHasRowClick] = React.useState<boolean>(false);

  const devMode = useSelector(
    (state: RootState) => state.developerMode.isEnabled
  );
  const { role, isSuperUser } = useRole();
  const { hasAnalytics } = useHasProperty();
  const [searchExp, setSearchExp] = React.useState<string>("");

  const [openDuplicateOnOtherSite, setOpenDuplicateOnOtherSite] =
    React.useState<boolean>(false);
  const [experienceDuplicationOnOtherSite, setExperienceDuplicateOnOtherSite] =
    React.useState<Dic<any>>({});
  const handleOpenDuplicateOnOtherSite = (
    experienceId: string,
    experienceName: string
  ) => {
    setExperienceDuplicateOnOtherSite({
      id: experienceId,
      name: experienceName,
    });
    setOpenDuplicateOnOtherSite(true);
  };
  const handleCloseDuplicateOnOtherSite = () => {
    setExperienceDuplicateOnOtherSite({});
    setOpenDuplicateOnOtherSite(false);
  };

  React.useEffect(() => {
    if (isSuperUser || role !== ROLE_ENUM.READER) {
      setHasRowClick(true);
    } else {
      setHasRowClick(false);
    }
  }, [role, isSuperUser]);

  const {
    handleClose,
    experienceToDelete,
    refetch,
    show,
    newRows,
    filteredColumns,
    handleEdit,
    canCreate,
    gettingExperiences,
    handleReport,
  } = useExperienceList(
    searchType,
    hasAnalytics,
    searchExp,
    handleOpenDuplicateOnOtherSite
  );

  React.useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <DeleteExperienceDialog
        handleClose={handleClose}
        open={!!experienceToDelete.id}
        experienceId={experienceToDelete.id}
        experienceName={experienceToDelete.name}
        updateExperiences={refetch}
      />
      <DuplicateInOtherSiteDialog
        open={openDuplicateOnOtherSite}
        handleClose={handleCloseDuplicateOnOtherSite}
        experienceId={experienceDuplicationOnOtherSite?.id}
        experienceName={experienceDuplicationOnOtherSite?.name}
      />
      <Box
        sx={{
          padding: 0,
          borderRadius: 3,
          maxWidth: "99%",
          "& .MuiDataGrid-row.has-row-click:hover": {
            background: `${theme.customPalette.others.background} !important`,
            cursor: "pointer",
          },
        }}
      >
        <Stack spacing={1}>
          <Collapse in={show}>
            <DataGrid
              localeText={{
                footerTotalRows: t("total_rows"),
              }}
              onCellClick={(params) => {
                if (params.field != "actions") {
                  if (hasAnalytics && params.row.canReport) {
                    handleReport(params.row.id);
                  } else if (params.row.canEdit && hasRowClick) {
                    handleEdit(params.row.id);
                  }
                }
              }}
              getRowClassName={(params) =>
                (hasRowClick || params.row.canReport) && `has-row-click`
              }
              currentPage={0}
              pageSize={newRows.length}
              autoHeight
              rows={newRows}
              columns={filteredColumns}
              loading={gettingExperiences}
              componentsProps={{
                noRowsOverlay: {
                  canCreate,
                  handleEdit,
                  refetch,
                  requiredIntegrations,
                },
                toolbar: {
                  search: searchExp,
                  setSearch: setSearchExp,
                },
              }}
              components={{
                NoRowsOverlay,
                Toolbar,
              }}
              paginationType="server"
              pagination={false}
            />
          </Collapse>
        </Stack>
      </Box>
    </>
  );
};

export default ExperienceList;
