export enum COMMITMENT_HEADERS {
  DISPLAYS = "displays",
  USAGES = "usages",
  GO_TO_PAGE = "go_to_page",
  ADD_TO_CART = "add_to_cart",
  CLOSURES = "closures",
}

export enum DISPLAYS_PER_DAY_HEADERS {
  DAY = "day",
  DISPLAYS = "displays",
}

export enum VISITORS_PER_DAY_HEADERS {
  DAY = "day",
  VISITORS = "visitors",
}

export enum TRANSACTIONS_PER_DAY_HEADERS {
  DAY = "day",
  TRANSACTIONS = "transactions",
}

export enum REVENUES_PER_DAY_HEADERS {
  DAY = "day",
  REVENUES = "revenues",
}

export enum SUMMARY_HEADERS {
  VISITORS = "visitors",
  TRANSACTIONS = "transactions",
  REVENUES = "revenues",
  TRANSACTION_RATE = "transaction_rate",
  REVENUE_PER_USER = "revenue_per_user",
  AVERAGE_CART = "average_cart",
}
