import { useHasProperty, useSetState } from "@/hooks";
import { Text } from "@/includes";
import { client } from "@/index";
import { potionsLocalStorage } from "@/services/LocalStorage/localStorage";
import { sendNotification } from "@/services/redux/actions";
import { RootState } from "@/services/redux/store";
import { experiencesUtils } from "@/utils";
import { useTheme } from "@mui/material";
import dayjs from "dayjs";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { getColumns } from "./columns";
import {
  FIRST,
  MAPPED_COLUMNS_TO_HIDE,
  mapSearchTypeToKey,
  mapSearchTypeToKeyBuilder,
} from "./enums";
import {
  CREATE_COLLECTION,
  CREATE_EXPERIENCE,
  GET_EXPERIENCES,
  GET_COLLECTION,
} from "./requests";
import { compareExperiences } from "./helpers";
import Overview from "./components/Overview";
import { searchType } from "./types";
import { useQuery } from "@apollo/client";
import { isEmpty } from "lodash";

const getOverviewData = (columnToShow: string, experience: Dic<any>) => {
  if (!experience.isMonitorable) return <Text> - </Text>;
  return <Overview columnToShow={columnToShow} experience={experience} />;
};

const useExperienceList = (
  searchType: searchType,
  hasAnalytics: boolean,
  searchExp: string,
  handleOpenDuplicateOnOtherSite: (
    experienceId: string,
    experienceName: string
  ) => void
) => {
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const accountId = useSelector((state: RootState) => state.account.accountId);
  const hasBuilder = useSelector(
    (state: RootState) => state.site.hasAlgorithmBuilder
  );
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t }: i18translateType = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [idOfMenuOpen, setIdOfMenuOpen] = React.useState<string>("");
  const devMode = useSelector(
    (state: RootState) => state.developerMode.isEnabled
  );

  const { hasCatalog, hasEmail, hasCmsTools } = useHasProperty();

  const [totalCount, setTotalCount] = React.useState(FIRST);
  const [experienceToDelete, setExperienceToDelete] = useSetState({
    id: "",
    name: "",
  });
  const [status, setStatus] = React.useState<
    "" | "success" | "loading" | "error"
  >("loading");

  const canEdit = React.useMemo(() => {
    if (searchType === "MULTIPLY" && hasCatalog) return true;
    if (searchType === "EMAILING" && hasEmail && hasCatalog) return true;
    if (!!hasCatalog && searchType === "EMERCH") return true;
    if (devMode && ["MULTIPLY", "EMAILING", "EMERCH"].includes(searchType))
      return true;
    return false;
  }, [searchType, hasCmsTools, hasCatalog, hasEmail, devMode]);

  const canCreate = React.useMemo(() => {
    if (searchType === "MULTIPLY" && hasCatalog) return true;
    if (searchType === "EMAILING" && hasEmail && hasCatalog) return true;
    if (!!hasCmsTools && searchType === "EMERCH") return true;
    if (devMode && ["MULTIPLY", "EMAILING", "EMERCH"].includes(searchType))
      return true;
    return false;
  }, [searchType, hasCmsTools, hasCatalog, hasEmail, devMode]);

  const columnsToFilter = MAPPED_COLUMNS_TO_HIDE[searchType];

  const filteredColumns = React.useMemo(
    () =>
      getColumns(
        t,
        theme,
        hasAnalytics,
        devMode,
        handleOpenDuplicateOnOtherSite,
        hasBuilder
      ).filter(
        (c: Dic<any>) => !columnsToFilter.find((cf: string) => cf === c.field)
      ),
    [anchorEl, hasAnalytics]
  );

  const canDelete =
    !!["MULTIPLY", "EMAILING", "EMERCH"].includes(searchType) || devMode;

  const { data, loading, error, refetch } = useQuery(GET_EXPERIENCES, {
    variables: {
      siteId: parseInt(siteId),
      cursor: "",
      first: totalCount + 5,
      searchType,
      search: searchExp,
      sort: "deployed_at desc, id desc",
    },
  });

  React.useEffect(() => {
    if (
      !isEmpty(data?.experiences?.edges) &&
      data?.experiences?.edges < data.experiences.totalCount
    ) {
      setTotalCount(data.experiences.totalCount);
    }
  }, [data]);

  const dataExperiences = [
    ...(data?.experiences?.edges?.map((item: Dic<any>) => ({
      ...item.node,
    })) ?? []),
  ];

  const experiences = devMode
    ? dataExperiences
    : dataExperiences.filter(
        (experience: Dic<any>) => !experience?.settings?.interface?.hidden
      );

  const handleEdit = (id: string) => {
    potionsLocalStorage.set("ExperienceTab", "Configure");
    if (hasBuilder) {
      history.push(
        `/${accountId}/${siteId}/${
          mapSearchTypeToKeyBuilder[searchType]
        }_builder/${id === "create" ? "" : `${id}`}`
      );
    } else {
      history.push(
        `/${accountId}/${siteId}/${mapSearchTypeToKey[searchType]}/${id}`
      );
    }
  };

  const handleReport = (id: string) => {
    potionsLocalStorage.set("ExperienceTab", "Report");
    history.push(
      `${location.pathname}/${id}?tab=experience&experienceId=${id}&noSelector=true`
    );
  };

  const handleClose = () => {
    setExperienceToDelete({ id: "", name: "" });
  };

  const handleDelete = React.useCallback((id: string, name: string) => {
    setExperienceToDelete({ id, name });
  }, []);

  const getCollection = async (collectionId: string): Promise<Dic<any>> => {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: GET_COLLECTION,
          variables: { id: collectionId },
        })
        .then((res) => {
          const { id, name, description, settings } = res.data.collection;
          resolve({ id, name, description, settings });
        })
        .catch(reject);
    });
  };

  const createCollection = async (
    collectionInfos: Dic<any>
  ): Promise<string> => {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: CREATE_COLLECTION,
          variables: { ...collectionInfos, id: siteId },
        })
        .then((res) => resolve(res.data.createCollection.id))
        .catch(reject);
    });
  };

  const createExperience = async (experience: Dic<any>) => {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: CREATE_EXPERIENCE,
          variables: { siteId, experience },
        })
        .then(() => {
          refetch();
        })
        .catch(reject);
    });
  };

  const copySettings = (experienceToDuplicate: Dic<any>) => {
    const { settings } = experienceToDuplicate;
    if (!settings) return settings;
    let copySettings = { ...settings };
    if (copySettings.brevo_feed_name) delete copySettings.brevo_feed_name;
    if (copySettings.brevo_feed_uuid) delete copySettings.brevo_feed_uuid;
    if (copySettings.variables) copySettings = JSON.stringify(copySettings);
    return copySettings;
  };

  const handleDuplicate = (experienceToDuplicate: Dic<any>): void => {
    setStatus("loading");
    getCollection(experienceToDuplicate.collection?.id)
      .then(createCollection)
      .then((collectionId: string) =>
        createExperience({
          collectionId,
          locationId: experienceToDuplicate.locationId ?? null,
          includedCategories: experienceToDuplicate.includedCategories ?? null,
          excludedCategories: experienceToDuplicate.excludedCategories ?? null,
          name: `Copy of ${experienceToDuplicate.name}`,
          type: experienceToDuplicate.type,
          settings: copySettings(experienceToDuplicate),
        })
      )
      .catch(() => {
        dispatch(
          sendNotification(
            "error",
            t("error_occurred"),
            `${t("error_copy_experience")} ${experienceToDuplicate.name}`,
            "error"
          )
        );
        setStatus("");
      });
  };

  const noExperiences = experiences.length === 0;

  const newRows = React.useMemo(
    () =>
      experiences.map((experience: Dic<any>) => ({
        ...experience,
        canCreate,
        canDelete,
        canEdit: canEdit && experience.isConfigurable,
        canReport:
          experiencesUtils.hasReport(
            experience.type,
            experience.isMonitorable
          ) && experience?.deployedAt,
        report: () => handleReport(experience.id),
        delete: () => handleDelete(experience.id, experience.name),
        edit: () => handleEdit(experience.id),
        duplicate: () => handleDuplicate(experience),
        goToSettings: () =>
          history.replace(
            `/${accountId}/${siteId}/recommendations/settings/${experience.id}`
          ),
        isPreview: experience.previews.edges.length > 0,
        location: [t(experience.pageType?.toLowerCase()), experience.uuid],
        previewLink: experience.previews.edges?.[0]?.node?.link ?? null,
        isActive: !!experience.deployedAt,
        activationDate: experience.deployedAt
          ? dayjs(experience.deployedAt).format("DD/MM/YY")
          : "",
        userRate: () => getOverviewData("users_over_exposed", experience),
        weightInCa: () => getOverviewData("revenues_from_users", experience),
        impact: () =>
          getOverviewData(
            "revenues_per_users_over_revenues_per_exposed",
            experience
          ),
      })),
    [experiences]
  );

  const sortedRows = React.useMemo(
    () => newRows.sort(compareExperiences),
    [newRows]
  );

  const [show, setShow] = React.useState(true);

  return React.useMemo(
    () => ({
      canEdit,
      handleEdit,
      handleClose,
      experienceToDelete,
      refetch,
      noExperiences,
      show,
      totalCount,
      newRows: sortedRows,
      filteredColumns,
      gettingExperiences: loading,
      handleReport,
      canCreate,
    }),
    [
      canCreate,
      canEdit,
      handleEdit,
      handleClose,
      experienceToDelete,
      refetch,
      noExperiences,
      show,
      totalCount,
      sortedRows,
      filteredColumns,
      status,
      handleReport,
    ]
  );
};

export default useExperienceList;
