import GridToolbar from "@/components/_Molecules/GridToolbar";
import DataGridClientPagination from "@/components/_Organisms/DataGridClientPagination";
import Slide from "@/components/_Organisms/ProductList/components/Slide";
import { usePersistedState, useProducts } from "@/hooks";
import useProductsColumns from "@/hooks/useProductsColumns";
import { DataGrid, PreviewViewSettings, StyledTabPanel } from "@/includes";
import { RootState } from "@/services/redux/store";
import { TabContext, TabList } from "@mui/lab";
import { Box, CircularProgress, Stack, Tab, useTheme } from "@mui/material";
import { GridColumnVisibilityModel } from "@mui/x-data-grid";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import productsColumns from "./columns";
import { TAB_PREVIEW_ENUMS } from "./enums";
import { DisplayPreviewProps } from "./types";

const DisplayPreview = ({
  collectionResult,
  runningCollection,
  loading,
  slot,
}: DisplayPreviewProps) => {
  const { t } = useTranslation();
  const [displayType, setDisplayType] = useState("list");
  const theme = useTheme();
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const currency = useSelector((state: RootState) => state.catalog.currency);
  const [datagridColumns, setDatagridColumns] = useState<Array<any>>([]);
  const columns = React.useMemo(
    () => productsColumns(theme, t, currency),
    [theme]
  );
  const { getCatalogColumns, getInitialColumns } = useProductsColumns(currency);
  const catalogColumns = useSelector(
    (state: RootState) => state.catalog.catalogColumns
  );
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageSize, setPageSize] = usePersistedState({
    key: "runPageSize-1",
    initialValue: 100,
  });

  const apiRef = useGridApiRef();

  useEffect(() => {
    const additionnalsColumns = getCatalogColumns(catalogColumns, t);
    setDatagridColumns([...columns, ...additionnalsColumns]);
  }, [columns]);
  const [columnVisibilityModel, setColumnVisibilityModel] = usePersistedState({
    key: `productsTableVisibilityModel-preview-${siteId}`,
    initialValue: {},
  });

  const [initialData, setInitialData] = useState<Dic<any>>({});

  useEffect(() => {
    if (!isEmpty(datagridColumns)) {
      setInitialData(getInitialColumns(datagridColumns));
    }
  }, [datagridColumns]);

  useEffect(() => {
    if (isEmpty(columnVisibilityModel) && !isEmpty(initialData)) {
      setColumnVisibilityModel(initialData);
    } else if (!isEmpty(initialData)) {
      setColumnVisibilityModel({ ...initialData, ...columnVisibilityModel });
    }
  }, [initialData]);

  const [tabValue, setTabValue] = useState<TAB_PREVIEW_ENUMS>(
    TAB_PREVIEW_ENUMS.LIST
  );

  const handleChangeTab = (
    event: React.SyntheticEvent | null,
    newValue: TAB_PREVIEW_ENUMS
  ) => {
    setTabValue(newValue);
  };
  const { getProductsInfos, isGettingProductInfos } = useProducts();
  const [productsToShowWithData, setProductsToShowWithData] = useState<
    Array<Dic<any>>
  >([]);

  useEffect(() => {
    if (!isEmpty(collectionResult)) {
      getProductsInfos(
        collectionResult?.map((pdToShow: Dic<any>) => pdToShow.id)
      ).then((res: Dic<any>) =>
        setProductsToShowWithData(
          res.map((resProduct: Dic<any>) => ({
            ...resProduct,
            ...resProduct.enrichedData,
          }))
        )
      );
    }
  }, [collectionResult]);
  return (
    <Stack>
      <TabContext value={tabValue}>
        <Stack
          direction="row"
          sx={{ width: "100%" }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              margin: "0 !important",
            }}
          >
            <TabList
              scrollButtons
              allowScrollButtonsMobile
              TabIndicatorProps={{
                style: {
                  backgroundColor: theme.customPalette.bluePurple.dark,
                },
              }}
              onChange={handleChangeTab}
              aria-label="lab API tabs example"
              sx={{
                "& .MuiButtonBase-root": {
                  alignItems: "end",
                },
              }}
            >
              <Tab
                key={TAB_PREVIEW_ENUMS.LIST}
                label={t("list")}
                value={TAB_PREVIEW_ENUMS.LIST}
                iconPosition="start"
              />
              <Tab
                key={TAB_PREVIEW_ENUMS.TABLE}
                label={t("table")}
                value={TAB_PREVIEW_ENUMS.TABLE}
                iconPosition="start"
              />
            </TabList>
          </Box>
          <Stack direction="row" alignItems="center" spacing={1}>
            {slot}
            {displayType === "list" && (
              <Box>
                <PreviewViewSettings />
              </Box>
            )}
          </Stack>
        </Stack>

        <StyledTabPanel value={TAB_PREVIEW_ENUMS.LIST}>
          <Box sx={{ minHeight: 300 }}>
            <Slide
              isLoading={loading || runningCollection}
              list="run result"
              products={collectionResult}
            />
          </Box>
        </StyledTabPanel>
        <StyledTabPanel value={TAB_PREVIEW_ENUMS.TABLE}>
          <Box sx={{ margin: "auto", minHeight: 300 }}>
            <Box sx={{ maxWidth: "100%", p: 1 }}>
              {isEmpty(columnVisibilityModel) ? (
                <CircularProgress />
              ) : (
                <DataGrid
                  paginationType="client"
                  rowCount={productsToShowWithData?.length}
                  rows={productsToShowWithData ?? []}
                  loading={
                    loading ||
                    runningCollection ||
                    isGettingProductInfos ||
                    (!isEmpty(collectionResult) &&
                      isEmpty(productsToShowWithData))
                  }
                  pageSize={pageSize}
                  currentPage={currentPage}
                  columns={datagridColumns}
                  componentsProps={{
                    pagination: {
                      totalItems: collectionResult.length,
                      pageSize,
                      handleChangePageSize: setPageSize,
                      currentPage,
                      handleChangeCurrentPage: setCurrentPage,
                    },
                    panel: {
                      sx: {
                        "& .MuiDataGrid-paper": {
                          backgroundColor: "unset",
                          background: theme.customPalette.others.layer_1,
                        },
                      },
                    },
                  }}
                  components={{
                    Pagination: DataGridClientPagination,
                    Toolbar: GridToolbar,
                  }}
                  columnVisibilityModel={columnVisibilityModel}
                  onColumnVisibilityModelChange={(
                    model: GridColumnVisibilityModel
                  ) => {
                    if (isEmpty(model)) {
                      setColumnVisibilityModel(initialData);
                    } else {
                      setColumnVisibilityModel(model);
                    }
                  }}
                  apiRef={apiRef}
                />
              )}
            </Box>
          </Box>
        </StyledTabPanel>
      </TabContext>
    </Stack>
  );
};

export default DisplayPreview;
