/* eslint-disable react/jsx-filename-extension */
import { Suspense } from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { LicenseInfo } from "@mui/x-license-pro";
import { ApolloProvider } from "@apollo/client";
import { Provider } from "react-redux";
import { client } from "./services/graphql/apolloConfig";
import i18next from "./services/i18n";
import App from "./App";
import store from "./services/redux/store";

LicenseInfo.setLicenseKey(
  "c4f68d9b3ccbe274fb3126efe9c1bb1bTz03NTg1MCxFPTE3Mjc5NjQ1NzcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

ReactDOM.hydrate(
  <I18nextProvider i18n={i18next}>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <Suspense fallback="loading...">
          <App />
        </Suspense>
      </ApolloProvider>
    </Provider>
  </I18nextProvider>,
  document.getElementById("root")
);

export { client };
// register();
