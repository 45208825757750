import { SEARCH_TYPE_ENUM } from "@/components/_Organisms/ExperienceList/enums";
import { useHasProperty, useViewport } from "@/hooks";
import { client } from "@/services/graphql/apolloConfig";
import { navigatorUtils } from "@/utils";
import { Box, Stack } from "@includes";
import { useTheme } from "@mui/material";
import { RootState } from "@store";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import HasAnalyticsOrABTestWrapper from "../../../HasAnalyticsOrABTestWrapper";
import HasCatalogWrapper from "../../../HasCatalogWrapper/HasCatalogWrapper";
import HasCustomPlanWrapper from "../../../HasCustomPlanWrapper";
import MobileWrapper from "../../../MobileWrapper";
import { HEIGHT_TOP_BAR } from "../../enums";
import Icon from "../Icon";
import OpenNavbar from "../OpenNavbar";
import { GET_EXPERIENCES } from "./requests";
import { SideMenuProps } from "./types";

const SideMenu = ({
  openDesktopNavbar = false,
  setOpenDesktopNavbar = () => {},
}: SideMenuProps) => {
  const open: any = useSelector((state: RootState) => state.drawer.open);
  const devMode = useSelector(
    (state: RootState) => state.developerMode.isEnabled
  );
  const { hasConditionalRules } = useHasProperty();

  const location = useLocation();
  const params = useParams<UrlParams>();
  const { siteId, accountId }: any = params;
  const history = useHistory();

  const theme = useTheme();

  const { t }: i18translateType = useTranslation();

  const [experiences, setExperiences] = useState<Array<any>>([]);
  const [totalExperiences, setTotalExperiences] = useState<number>(10);

  const getExperiences = () => {
    return client
      .query({
        query: GET_EXPERIENCES,
        variables: {
          siteId: parseInt(siteId),
          first: totalExperiences,
        },
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        setExperiences(
          res.data.experiences.edges.map((edge: Dic<any>) => edge.node)
        );
        setTotalExperiences(res.data.experiences.totalCount);
      });
  };

  useEffect(() => {
    if (experiences.length < totalExperiences) {
      getExperiences();
    }
  }, [totalExperiences]);

  const hasTargetPopUp = !isEmpty(
    experiences.find((elem) => elem.type === SEARCH_TYPE_ENUM.SWITCH)
  );
  const hasLastSessionRecovery = !isEmpty(
    experiences.find((elem) => elem.type === SEARCH_TYPE_ENUM.PROPULSE)
  );
  const hasRecentlyViewedProducts = !isEmpty(
    experiences.find((elem) => elem.type === SEARCH_TYPE_ENUM.FLASHBACK)
  );
  const hasAuthentificationAuto = !isEmpty(
    experiences.find((elem) => elem.type === SEARCH_TYPE_ENUM.CONNECT)
  );

  const { isMobile } = useViewport();
  return (
    <Box
      sx={{
        justifyContent: "center",
        display: "flex",
        backgroundColor: theme.customPalette.others.layer_1,
        overflow: "visible",
        height: isMobile ? 1 : `calc(100% - ${HEIGHT_TOP_BAR})`,
      }}
    >
      <OpenNavbar open={openDesktopNavbar} setOpen={setOpenDesktopNavbar} />
      <Box
        sx={{
          width: "100%",
          overflow: "hidden",
          display: "flex",
          justifyContent: isMobile ? "start" : "center",
          alignItems: "center",
          padding: isMobile ? "16px" : "0px",
          pt: 2,
        }}
      >
        <Stack
          sx={{
            height: "100%",
            maxWidth: "100%",
            overflowY: "auto",
            overflowX: "hidden",
            flex: isMobile ? 1 : "auto",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              WebkitAppearance: "none",
              display: "none",
            },
          }}
          justifyContent={isMobile ? "unset" : "space-between"}
        >
          <Stack
            spacing={isMobile ? "8px" : "12px"}
            sx={{ width: "100%" }}
            justifyContent="center"
            alignItems={isMobile || openDesktopNavbar ? "baseline" : "center"}
          >
            <MobileWrapper>
              <Box
                sx={{ height: 20, padding: 1, cursor: "pointer" }}
                component="img"
                src={`/${theme.customPalette.mode}/top_logo.svg`}
              />
            </MobileWrapper>
            <Icon
              tooltipInfos={{
                title: t("home"),
                description: t("home_description"),
                tag: "",
                position: "top",
              }}
              iconName="home"
              selected={location.pathname.includes("hom")}
              onClick={navigatorUtils.goTo(
                `/${accountId}/${siteId}/home`,
                history
              )}
              openDesktopNavbar={openDesktopNavbar}
            />
            <HasCustomPlanWrapper>
              <HasAnalyticsOrABTestWrapper>
                <Icon
                  tooltipInfos={{
                    title: t("report"),
                    description: t("report_description"),
                    tag: "",
                    position: "top",
                  }}
                  iconName="report"
                  selected={location.pathname.includes("report")}
                  onClick={navigatorUtils.goTo(
                    `/${accountId}/${siteId}/report`,
                    history
                  )}
                  openDesktopNavbar={openDesktopNavbar}
                />
              </HasAnalyticsOrABTestWrapper>
            </HasCustomPlanWrapper>
            <HasCatalogWrapper>
              <Icon
                tooltipInfos={{
                  title: t("catalog"),
                  description: t("catalog_description"),
                  tag: "",
                  position: "top",
                }}
                iconName="catalog"
                selected={
                  location.pathname.includes("catalog") &&
                  !location.pathname.includes("data/catalog")
                }
                onClick={navigatorUtils.goTo(
                  `/${accountId}/${siteId}/catalog`,
                  history
                )}
                openDesktopNavbar={openDesktopNavbar}
              />
            </HasCatalogWrapper>
            <Box
              sx={{
                width: "100%",
                height: 2,
                background: theme.customPalette.others.background,
              }}
            />
            <Icon
              tooltipInfos={{
                title: t("merchandising"),
                description: t("merchandising_description"),
                tag: "",
                position: "top",
              }}
              iconName="merchandising"
              selected={location.pathname.includes("merchandising")}
              onClick={navigatorUtils.goTo(
                `/${accountId}/${siteId}/merchandising`,
                history
              )}
              openDesktopNavbar={openDesktopNavbar}
            />
            <Icon
              tooltipInfos={{
                title: t("recos_web"),
                description: t("recommendations_on_website_description"),
                tag: "",
                position: "top",
              }}
              iconName="recommendations"
              selected={location.pathname.includes("recommendations/site")}
              onClick={navigatorUtils.goTo(
                `/${accountId}/${siteId}/recommendations/site`,
                history
              )}
              openDesktopNavbar={openDesktopNavbar}
            />
            <Icon
              tooltipInfos={{
                title: t("recos_mail"),
                description: t("recommendations_in_emails_description"),
                tag: "",

                position: "top",
              }}
              iconName="mail"
              selected={
                location.pathname.includes("recommendations/emailing") ||
                location.pathname.includes("emailing")
              }
              onClick={navigatorUtils.goTo(
                `/${accountId}/${siteId}/recommendations/emailing`,
                history
              )}
              openDesktopNavbar={openDesktopNavbar}
            />
            {devMode && (
              <Icon
                tooltipInfos={{
                  title: t("algorithms"),
                  tag: "",
                  position: "top",
                  noImage: true,
                }}
                iconName="algorithms"
                selected={location.pathname.includes("algorithms")}
                onClick={navigatorUtils.goTo(
                  `/${accountId}/${siteId}/algorithms`,
                  history
                )}
                openDesktopNavbar={openDesktopNavbar}
              />
            )}
            {!["31", "137", "138"].includes(accountId) && (
              <>
                {hasTargetPopUp && (
                  <Icon
                    tooltipInfos={{
                      title: t("targeted_popups"),
                      description: t("targeted_popups_description"),
                      tag: "",
                      position: "top",
                    }}
                    iconName="targeted_popups"
                    selected={location.pathname.includes("targeted_popups")}
                    onClick={navigatorUtils.goTo(
                      `/${accountId}/${siteId}/targeted_popups`,
                      history
                    )}
                    openDesktopNavbar={openDesktopNavbar}
                  />
                )}
                {hasRecentlyViewedProducts && (
                  <Icon
                    tooltipInfos={{
                      title: t("recently_viewed_products"),
                      description: t("recently_viewed_products_description"),
                      tag: "",

                      position: "top",
                    }}
                    iconName="recently_viewed_products"
                    selected={location.pathname.includes(
                      "recently_viewed_products"
                    )}
                    onClick={navigatorUtils.goTo(
                      `/${accountId}/${siteId}/recently_viewed_products`,
                      history
                    )}
                    openDesktopNavbar={openDesktopNavbar}
                  />
                )}
                {hasLastSessionRecovery && (
                  <Icon
                    tooltipInfos={{
                      title: t("last_session_recovery"),
                      description: t("last_session_recovery_description"),
                      tag: "",

                      position: "top",
                    }}
                    iconName="last_session_recovery"
                    selected={location.pathname.includes(
                      "last_session_recovery"
                    )}
                    onClick={navigatorUtils.goTo(
                      `/${accountId}/${siteId}/last_session_recovery`,
                      history
                    )}
                    openDesktopNavbar={openDesktopNavbar}
                  />
                )}
                {hasAuthentificationAuto && (
                  <Icon
                    tooltipInfos={{
                      title: t("automatic_authentication"),
                      description: t("automatic_authentication_description"),
                      tag: "",
                      position: "bottom",
                    }}
                    iconName="automatic_authentication"
                    selected={location.pathname.includes(
                      "automatic_authentication"
                    )}
                    onClick={navigatorUtils.goTo(
                      `/${accountId}/${siteId}/automatic_authentication`,
                      history
                    )}
                    openDesktopNavbar={openDesktopNavbar}
                  />
                )}
              </>
            )}
            <Box
              sx={{
                width: "100%",
                height: 2,
                background: theme.customPalette.others.background,
              }}
            />
            {!hasConditionalRules && (
              <HasCatalogWrapper>
                <Icon
                  tooltipInfos={{
                    title: t("rules"),
                    description: t("rules_description_sidemenu"),
                    tag: "",
                    position: "top",
                  }}
                  iconName="rules"
                  selected={location.pathname.includes("collections")}
                  onClick={navigatorUtils.goTo(
                    `/${accountId}/${siteId}/collections`,
                    history
                  )}
                  openDesktopNavbar={openDesktopNavbar}
                />
              </HasCatalogWrapper>
            )}
            {devMode && (
              <>
                <Box
                  sx={{
                    width: "100%",
                    height: 2,
                    background: theme.customPalette.others.background,
                  }}
                />

                <Icon
                  tooltipInfos={{
                    title: t("datasets"),
                    tag: "",
                    position: "top",
                    noImage: true,
                  }}
                  iconName="data"
                  selected={location.pathname.includes("datasets")}
                  onClick={navigatorUtils.goTo(
                    `/${accountId}/${siteId}/datasets/catalog`,
                    history
                  )}
                  openDesktopNavbar={openDesktopNavbar}
                />
                <Icon
                  tooltipInfos={{
                    title: t("campaigns"),
                    description: t("campaigns_description_sidemenu"),
                    tag: "",
                    position: "top",
                    noImage: true,
                  }}
                  iconName="campaigns"
                  selected={location.pathname.includes("campaigns")}
                  onClick={navigatorUtils.goTo(
                    `/${accountId}/${siteId}/campaigns`,
                    history
                  )}
                  openDesktopNavbar={openDesktopNavbar}
                />
              </>
            )}

            {devMode && (
              <>
                <Icon
                  tooltipInfos={{
                    title: t("placements"),
                    description: t("placements_description_sidemenu"),
                    tag: "",
                    position: "top",
                    noImage: true,
                  }}
                  iconName="locations"
                  selected={location.pathname.includes("placements")}
                  onClick={navigatorUtils.goTo(
                    `/${accountId}/${siteId}/placements`,
                    history
                  )}
                  openDesktopNavbar={openDesktopNavbar}
                />

                <Icon
                  tooltipInfos={{
                    title: t("variables"),
                    tag: "",
                    position: "top",
                    noImage: true,
                  }}
                  iconName="variables"
                  selected={location.pathname.includes("variables")}
                  onClick={navigatorUtils.goTo(
                    `/${accountId}/${siteId}/variables`,
                    history
                  )}
                  openDesktopNavbar={openDesktopNavbar}
                />
              </>
            )}
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default SideMenu;
