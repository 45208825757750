import { CloseIcon } from "@/assets/icons";
import { useDebounce } from "@/hooks";
import { RootState } from "@/services/redux/store";
import { comparisonUtils } from "@/utils";
import { setProductProperties } from "@actions";
import {
  Box,
  Checkbox,
  DeviceSelector,
  Dialog,
  IconButton,
  Stack,
  StyledTextField,
  Text,
} from "@includes";
import { FormControlLabel, useTheme } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IEditSettingsDialog } from "./types";
import { getTraduction } from "@/utils/helpers/traductions";

const EditSettingsDialog: React.FC<IEditSettingsDialog> = ({
  open,
  handleClose,
  deviceObj,
  showDeviceSelector,
}) => {
  const theme = useTheme();
  const { t }: i18translateType = useTranslation();

  const catalogColumns = useSelector(
    (state: RootState) => state.catalog.catalogColumns
  )?.filter(
    (p: Dic<any>) => !p?.name?.includes("link") && !p?.name?.includes("title")
  );

  const productProperties = useSelector(
    (state: RootState) => state.product.properties
  );

  const dispatch = useDispatch();

  const [search, setSearch] = React.useState("");

  const [columns, setColumns] = React.useState<Array<string>>([]);

  useDebounce(
    () => {
      if (search === "") {
        setColumns([
          ...catalogColumns?.map((col: Dic<string | undefined>) => col?.name),
        ]);
      } else
        setColumns(
          columns.filter(
            (col: any) =>
              col?.toLowerCase()?.includes(search) ||
              search?.toLowerCase()?.includes(col?.toLowerCase())
          )
        );
    },
    [search, catalogColumns],
    200
  );

  const handleChange = (isSelected: boolean, value: string) => {
    if (isSelected) {
      dispatch(
        setProductProperties([
          ...productProperties?.filter(comparisonUtils.areDifferent(value)),
        ])
      );
    } else {
      dispatch(setProductProperties([...productProperties, value]));
    }
  };

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      dispatch(setProductProperties([...columns]));
    } else {
      dispatch(setProductProperties([]));
    }
  };

  const allSelected =
    columns.length > 0 && productProperties.length === columns.length;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: `min(100vw, 520px)`,
            maxWidth: `min(100vw, 520px)`,
          },
        }}
      >
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="start"
            sx={{ padding: 1 }}
          >
            <Stack spacing={1} sx={{ width: "100%" }}>
              <Box
                sx={{ width: "24px", pt: 1 }}
                component="img"
                src={`/${theme.customPalette.mode}/icons/settings.svg`}
              />
              <Text variant="h3">{t("preview_view_settings")}</Text>
            </Stack>
            <IconButton onClick={handleClose}>
              <CloseIcon color={theme.customPalette.grey.default} />
            </IconButton>
          </Stack>
          <DialogContent sx={{ padding: 1 }}>
            <Stack spacing={2} sx={{ width: "100%" }}>
              {!!deviceObj && showDeviceSelector && (
                <DeviceSelector
                  customStyle={{ width: "100%" }}
                  device={deviceObj.device}
                  handleChange={deviceObj.handleChangeDevice}
                />
              )}
              <Stack>
                <Text>{t("select_property")}</Text>
                <StyledTextField
                  label={t("search_property")}
                  name={t("search_property")}
                  onChange={(e: any) => setSearch(e?.target?.value)}
                  value={search}
                  placeholder={t("category")}
                />
                <FormControlLabel
                  sx={{ cursor: "pointer" }}
                  control={
                    <Checkbox
                      checked={allSelected}
                      onChange={handleSelectAllChange}
                    />
                  }
                  label={t("all")}
                />
                <Stack sx={{ maxHeight: 300, overflowY: "auto" }}>
                  {columns?.map((value: string) => {
                    return (
                      <Box
                        key={value}
                        sx={{
                          p: "4px 2px",
                          "&:hover": {
                            background: theme.customPalette.others.background,
                          },
                        }}
                      >
                        <FormControlLabel
                          sx={{ width: "95%", cursor: "pointer" }}
                          control={
                            <Checkbox
                              checked={
                                !!productProperties?.find(
                                  comparisonUtils.areEquals(value)
                                )
                              }
                              onChange={() =>
                                handleChange(
                                  productProperties?.find(
                                    comparisonUtils.areEquals(value)
                                  ),
                                  value
                                )
                              }
                            />
                          }
                          label={getTraduction(t, value)}
                        />
                      </Box>
                    );
                  })}
                </Stack>
              </Stack>
            </Stack>
          </DialogContent>
        </Stack>
      </Dialog>
    </div>
  );
};

export default EditSettingsDialog;
