interface IBackMenu {
  color: string;
}

const BackMenu: React.FC<IBackMenu> = ({ color }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="chevronCircleLeftIcon"
  >
    <g id="Size=16px, Property=Circle 1, Sens=Left">
      <path
        id="Stroke 1 (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75822 13.2416C1.61671 12.1001 1.2334 10.3454 1.2334 7.99978C1.2334 5.65417 1.61671 3.89944 2.75822 2.75794C3.89972 1.61643 5.65445 1.23312 8.00006 1.23312C10.3457 1.23312 12.1004 1.61643 13.2419 2.75794C14.3834 3.89944 14.7667 5.65417 14.7667 7.99978C14.7667 10.3454 14.3834 12.1001 13.2419 13.2416C12.1004 14.3831 10.3457 14.7664 8.00006 14.7664C5.65445 14.7664 3.89972 14.3831 2.75822 13.2416ZM3.60675 12.3931C4.39241 13.1788 5.72101 13.5664 8.00006 13.5664C10.2791 13.5664 11.6077 13.1788 12.3934 12.3931C13.179 11.6074 13.5667 10.2788 13.5667 7.99978C13.5667 5.72073 13.179 4.39213 12.3934 3.60646C11.6077 2.8208 10.2791 2.43312 8.00006 2.43312C5.72101 2.43312 4.39241 2.8208 3.60675 3.60646C2.82108 4.39213 2.4334 5.72073 2.4334 7.99978C2.4334 10.2788 2.82108 11.6074 3.60675 12.3931Z"
        fill={color}
      ></path>
      <path
        id="Stroke 3 (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.85819 10.6535C9.67081 10.9268 9.29735 10.9964 9.02404 10.8091L9.36332 10.3142C9.02404 10.8091 9.02411 10.8091 9.02404 10.8091L9.02153 10.8073L9.0157 10.8033L8.99449 10.7886C8.97626 10.7759 8.94999 10.7575 8.91685 10.7341C8.8506 10.6872 8.75663 10.6199 8.64416 10.5369C8.41995 10.3717 8.11865 10.1418 7.81527 9.88685C7.51515 9.6346 7.19743 9.34478 6.94963 9.05973C6.82596 8.91747 6.70735 8.76242 6.61679 8.60214C6.53141 8.45101 6.43932 8.24057 6.43932 8.00087C6.43932 7.7612 6.53139 7.55075 6.61675 7.39962C6.70729 7.23933 6.82588 7.08423 6.94953 6.94192C7.19731 6.65676 7.515 6.36678 7.81512 6.11436C8.11848 5.85921 8.41978 5.62923 8.64398 5.46381C8.75645 5.38083 8.85041 5.31344 8.91666 5.26651C8.94981 5.24304 8.97607 5.22465 8.9943 5.21194L9.01551 5.19723L9.02134 5.1932L9.02302 5.19205C9.02309 5.192 9.02385 5.19148 9.36332 5.68621L9.02385 5.19148C9.29708 5.00399 9.67057 5.0735 9.85805 5.34674C10.0455 5.61994 9.97604 5.99339 9.70287 6.18089C9.70284 6.1809 9.70289 6.18087 9.70287 6.18089L9.70193 6.18153L9.6978 6.18438L9.68014 6.19664C9.66433 6.20765 9.64067 6.22422 9.61026 6.24576C9.54942 6.28885 9.46182 6.35167 9.35642 6.42943C9.14487 6.58552 8.86516 6.79921 8.58753 7.03272C8.30664 7.26897 8.04334 7.51265 7.85536 7.72899C7.76114 7.83743 7.69817 7.92504 7.66161 7.98977C7.6594 7.99368 7.65737 7.99737 7.65549 8.00085C7.65736 8.0043 7.65938 8.00797 7.66157 8.01185C7.69811 8.07652 7.76106 8.16407 7.85526 8.27244C8.04321 8.48864 8.30649 8.73215 8.58737 8.96823C8.86499 9.20157 9.14469 9.4151 9.35624 9.57106C9.46163 9.64876 9.54924 9.71152 9.61008 9.75458C9.64048 9.7761 9.66415 9.79266 9.67995 9.80366L9.69761 9.8159L9.70174 9.81875L9.70259 9.81933C9.70261 9.81935 9.70256 9.81932 9.70259 9.81933M9.85819 10.6535C10.0455 10.3802 9.97583 10.0067 9.70259 9.81933L9.85819 10.6535Z"
        fill={color}
      ></path>
    </g>
  </svg>
);

export default BackMenu;
