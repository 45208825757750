import { useEffect, useState } from "react";
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  map,
  merge,
  of,
  switchMap,
} from "rxjs";
import useSetState from "../useSetState";
import useDidMountEffect from "../useDidMountEffect";
import { isEmpty } from "lodash";

/**
 * Custom React hook for handling search functionality.
 *
 * @param term - The search term.
 * @param callback - The callback function that performs the search.
 * @param otherDeps - Additional dependencies that trigger a search.
 * @returns An object containing the search result and loading state.
 */
const useSearch = (
  term: string,
  callback: (...params: any) => Promise<any>,
  ...otherDeps: any
) => {
  // Create a BehaviorSubject to handle the search term updates
  const [subject, setSubject] = useState<BehaviorSubject<any> | null>(null);
  const [state, setState] = useSetState({ searchResult: [], loading: false });

  // Function to handle changes in the search term
  const handleChangeTerm = () => {
    if (subject) {
      return subject.next(term);
    }
  };

  useDidMountEffect(() => {
    handleChangeTerm();
  }, [term]);

  // Effect to perform the search and update the state
  useDidMountEffect(() => {
    setState({ ...state, loading: true });
    callback(term).then((searchResult) => {
      setState({ searchResult, loading: false });
    });
  }, [...otherDeps]);

  // Main effect to set up the BehaviorSubject and handle its changes
  useEffect(() => {
    if (subject === null) {
      // Initialize a new BehaviorSubject if not already present
      const sub = new BehaviorSubject("");
      setSubject(sub);
    } else {
      // Set up an observable for the search term changes
      const observable = subject
        .pipe(
          map((s) => (isEmpty(s) ? "" : s.trim())),
          distinctUntilChanged(),
          debounceTime(600),
          switchMap((term) =>
            merge(
              of({ loading: true }),
              callback(term).then((searchResult) => {
                return {
                  searchResult,
                  loading: false,
                };
              })
            )
          )
        )
        .subscribe((newState) => {
          setState(newState);
        });

      // Clean up the observable on component unmount
      return () => {
        observable.unsubscribe();
        subject.unsubscribe();
      };
    }
  }, [subject]);

  // Return the state object containing search results and loading state
  return { ...state };
};

export default useSearch;
