import {
  DeleteIcon,
  DuplicateIcon,
  EditIcon,
  ReportIcon,
  SettingsIcon,
} from "@/assets/icons";
import MoreVerticalOutlinedIcon from "@/assets/icons/MoreVerticalOutlined";
import VisibilityIcon from "@/assets/icons/Visibility";
import HasEditingRoleWrapper from "@/components/_Templates/HasEditingRoleWrapper";
import { HoverCopyText, Tag, Text } from "@/includes";
import { Box, Menu, MenuItem, Stack, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import * as React from "react";

export const getColumns = (
  t: (text: string) => string,
  theme: Dic<any>,
  hasAnalytics: boolean,
  devMode: boolean,
  handleOpenDuplicateOnOtherSite: (
    experienceId: string,
    experienceName: string
  ) => void,
  hasBuilder: boolean = false
): GridColDef[] => [
  {
    align: "left",
    field: "name",
    headerName: t("name"),
    flex: 1,
    minWidth: 120,
  },
  {
    align: "left",
    field: "status",
    headerName: t("status"),
    sortable: false,
    width: 120,
    renderCell: (params: any) => {
      return (
        <Stack>
          {params.row.isActive && <Tag tagVariant="success">{t("active")}</Tag>}
          {params.row.isPreview && (
            <Tag tagVariant="info">{t("in_preview")}</Tag>
          )}
          {!params.row.isActive &&
            !!params.row.name &&
            !params.row.isPreview && (
              <Tag tagVariant="info">{t("inactive")}</Tag>
            )}
        </Stack>
      );
    },
  },
  {
    align: "left",
    field: "uuid",
    headerName: t("uuid"),
    flex: 1,
    minWidth: 120,
    renderCell: (params: any) => {
      return <HoverCopyText text={params.row.uuid} noWrap />;
    },
  },
  {
    align: "left",
    field: "activationDate",
    headerName: t("activation_date"),
    width: 150,
  },
  {
    align: "left",
    width: 120,
    sortable: false,
    field: "userRate",
    hide: !hasAnalytics,
    headerName: t("users_over_exposed"),
    renderCell: (params: any) => {
      return <>{params.row.userRate()}</>;
    },
  },
  {
    align: "left",
    width: 120,
    sortable: false,
    field: "weightInCa",
    hide: !hasAnalytics,
    headerName: t("revenues_from_exposed"),
    renderCell: (params: any) => {
      return <>{params.row.weightInCa()}</>;
    },
  },
  {
    align: "left",
    width: 120,
    field: "impact",
    sortable: false,
    hide: !hasAnalytics,
    headerName: t("revenues_per_users_over_revenues_per_exposed"),
    renderCell: (params: any) => {
      return <>{params.row.impact()}</>;
    },
  },
  {
    align: "right",
    field: "actions",
    headerName: "",
    width: 150,
    sortable: false,
    renderCell: (params: any) => {
      return (
        <>
          <Stack direction="row" sx={{ gap: 1 }}>
            <HasEditingRoleWrapper>
              {params.row.canEdit && (
                <Tooltip title={t("configure")}>
                  <Box
                    onClick={(e: React.SyntheticEvent) => {
                      e.stopPropagation();
                      params.row.edit();
                    }}
                    sx={{
                      gap: 1,
                      "& svg": { width: 16, height: 16 },
                      cursor: "pointer",
                    }}
                  >
                    <EditIcon color={theme.customPalette.grey.default} />
                  </Box>
                </Tooltip>
              )}
              {params.row.canEdit && (
                <Tooltip title={t("duplicate")}>
                  <Box
                    onClick={(event: React.SyntheticEvent) => {
                      event.preventDefault();
                      event.stopPropagation();
                      params.row.duplicate();
                    }}
                    sx={{
                      gap: 1,
                      "& svg": { width: 16, height: 16 },
                      cursor: "pointer",
                    }}
                  >
                    <DuplicateIcon color={theme.customPalette.grey.default} />
                  </Box>
                </Tooltip>
              )}
              {hasBuilder && params.row.canEdit && (
                <Tooltip title={t("duplicate_in_other_site")}>
                  <Box
                    onClick={(event: React.SyntheticEvent) => {
                      event.preventDefault();
                      handleOpenDuplicateOnOtherSite(
                        params?.row?.id,
                        params?.row?.name
                      );
                    }}
                    sx={{
                      gap: 1,
                      "& svg": { width: 16, height: 16 },
                      cursor: "pointer",
                    }}
                  >
                    <DuplicateIcon color={theme.customPalette.grey.default} />
                  </Box>
                </Tooltip>
              )}
              {params.row.canDelete && (
                <Tooltip title={t("delete")}>
                  <Box
                    onClick={(event: React.SyntheticEvent) => {
                      event.preventDefault();
                      event.stopPropagation();
                      params.row.delete();
                    }}
                    sx={{
                      gap: 1,
                      "& svg": { width: 16, height: 16 },
                      cursor: "pointer",
                    }}
                  >
                    <DeleteIcon color={theme.customPalette.grey.default} />
                  </Box>
                </Tooltip>
              )}
            </HasEditingRoleWrapper>
            {hasAnalytics && params.row.canReport && (
              <Tooltip title={t("report")}>
                <Box
                  onClick={(event: React.SyntheticEvent) => {
                    event.preventDefault();
                    event.stopPropagation();
                    params.row.report();
                  }}
                  sx={{
                    gap: 1,
                    "& svg": { width: 16, height: 16 },
                    cursor: "pointer",
                  }}
                >
                  <ReportIcon color={theme.customPalette.grey.default} />
                </Box>
              </Tooltip>
            )}
            {params.row.previewLink && (
              <Tooltip title={t("preview")}>
                <Box
                  onClick={(event: React.SyntheticEvent) => {
                    event.stopPropagation();
                    window.open(params.row.previewLink);
                  }}
                  sx={{
                    gap: 1,
                    "& svg": { width: 16, height: 16 },
                    cursor: "pointer",
                  }}
                >
                  <VisibilityIcon color={theme.customPalette.grey.default} />
                </Box>
              </Tooltip>
            )}
            {devMode && (
              <Tooltip title={t("settings")}>
                <Box
                  onClick={(event: React.SyntheticEvent) => {
                    event.stopPropagation();
                    params.row.goToSettings();
                  }}
                  sx={{
                    gap: 1,
                    "& svg": { width: 16, height: 16 },
                    cursor: "pointer",
                  }}
                >
                  <SettingsIcon color={theme.customPalette.grey.default} />
                </Box>
              </Tooltip>
            )}
          </Stack>
          {/* </Menu> */}
        </>
      );
    },
  },
];
