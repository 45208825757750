import SearchBar from "@/components/_Atoms/SearchBar";
import { Stack } from "@mui/material";
import { ToolbarProps } from "./types";

const Toolbar = ({ search, setSearch }: ToolbarProps) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      sx={{ py: 1 }}
    >
      <SearchBar search={search} setSearch={setSearch} />
    </Stack>
  );
};

export default Toolbar;
